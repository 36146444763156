import React, { useEffect } from 'react';
import { notification } from 'antd';
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

const AntdNotification = () => {
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (
    title,
    description,
    iconType,
    duration = undefined
  ) => {
    let IconComponent = null;
    let className;
    switch (iconType) {
      case 'fail':
        IconComponent = CloseCircleOutlined;
        className = 'notification-fail';
        duration = duration === undefined ? 0 : duration;
        break;
      case 'success':
        IconComponent = CheckCircleOutlined;
        className = 'notification-success';
        duration = duration === undefined ? 2 : duration;
        break;
      case 'info':
        IconComponent = InfoCircleOutlined;
        className = 'notification-info';
        duration = duration === undefined ? 10 : duration;
        break;
      default:
        IconComponent = InfoCircleOutlined;
        className = 'notification-info';
        duration = duration === undefined ? 10 : duration;
    }
    api.open({
      message: title,
      description: description,
      duration: duration,
      icon: <IconComponent className={className} />,
    });
  };

  useEffect(() => {
    window.antdNotify = (title, description, iconType) => {
      openNotification(title, description, iconType);
      // return new Promise((resolve) => {
      //   alertConfirm = resolve;
      // });
    };
  }, []);

  return <>{contextHolder}</>;
};

export default AntdNotification;
