import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Space, Col, Row, Spin, Empty } from 'antd';
import { WebviewHttpHandler } from 'shared/webviewHttpHandler';
const httpHandler = new WebviewHttpHandler();
// 排序寫死
const amenityListSort = [
  '腰背按摩器',
  '腰靠墊',
  '置物籃',
  '手機架',
  '充電線',
  '行動電源',
  '兒童座椅',
];
const ServiceItem = ({ src, alt, title }) => (
  <Col xs={12} sm={6} md={6}>
    {' '}
    {/* 響應式列跨度設置 */}
    <div className="item-container">
      {src ? (
        <img src={src} alt={alt} style={{ width: '100%' }} />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={'尚未提供圖片'}
        />
      )}
      <div className="body1 text-ellipsis">{title}</div>
    </div>
  </Col>
);

const LoungeService = () => {
  const [userLocationCode, setUserLocationCode] = useState();
  const [searchStatus, setSearchStatus] = useState(1);
  const [menuList, setMenuList] = useState([]);
  // 重組response的item排序
  const reformatItemSort = (sortList, resList) => {
    var newList = [];
    sortList.map((item) => {
      const index = resList.findIndex((coldDrink) => coldDrink.name === item);

      if (index !== -1) {
        const sortItem = resList.splice(index, 1)[0];
        newList.push(sortItem);
      }
    });
    newList.push(...resList);
    return newList;
  };
  useEffect(() => {
    httpHandler
      .get(
        `user_location/get_user_location?user_id=${localStorage.getItem(
          'lineUserId'
        )}`
      )
      .then((res) => {
        if (res.success) {
          setUserLocationCode(res.data.site_id);
        } else {
          window.antdNotify('錯誤', res.data.message, 'fail');
        }
      });
  }, []);
  useEffect(() => {
    if (userLocationCode) {
      httpHandler
        .get(`/webview/custom_lounge/list?location_code=${userLocationCode}`)
        .then((res) => {
          if (res.success) {
            // 客休服務菜單
            const amenityList = res.data.filter(
              (item) => item.item_type == '2'
            );
            setMenuList(reformatItemSort(amenityListSort, amenityList));
            setSearchStatus(0);
          } else {
            window.antdNotify('錯誤', res.message, 'fail');
          }
        });
    }
  }, [userLocationCode]);
  return (
    // Webview客製化Title
    (document.title = 'TOYOTA客休服務'),
    (
      <div className="menu-container">
        <Space direction="vertical" size="middle">
          <Space direction="vertical" size="small">
            <img
              src="/images/logo/toyota_logo_horizontal.svg"
              alt="Toyota Logo"
              style={{ width: '120px' }}
            />
            <div className="heading1">客休服務</div>
            <div className="body1 grey">
              實際品項及供應數量以服務廠提供為主
              <br />
              歡迎向客服專員取用
            </div>
          </Space>
          {searchStatus === 1 ? (
            <Spin size="large" />
          ) : (
            <Row gutter={[16, 16]}>
              {menuList.map((item) => (
                <ServiceItem
                  key={item.id}
                  src={item.image_base64}
                  alt={item.name}
                  title={item.name}
                />
              ))}
            </Row>
          )}
        </Space>
      </div>
    )
  );
};

export default LoungeService;
