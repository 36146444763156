import { React, useState, useEffect } from 'react';
import PageHeader from 'utility/PageHeader';
import PageBody from 'utility/PageBody';
import { Button, Form, Input, Row, Col, Table, Modal, Image } from 'antd';
import {
  SearchOutlined,
  QrcodeOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { HttpHandler } from '../../shared/httpHandler';
const httpHandler = new HttpHandler();

const locationTypeMapping = { 1: '服務廠', 2: '保修站' };

const QRCode = () => {
  const [searchForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalImageData, setModalImageData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    updateData(pagination);
  };

  const updateData = (pagination = tableParams.pagination) => {
    let { name, code } = searchForm.getFieldsValue();
    name = name ? name.trim() : '';
    code = code ? code.trim() : '';
    const { current, pageSize } = pagination;
    setLoading(true);
    httpHandler
      .get(
        `/service_location/list?name=${name}&location_code=${code}&page=${current}&page_size=${pageSize}&is_all=false`
      )
      .then((response) => {
        if (response.success) {
          setTableData(response.data);
          setTableParams({
            ...tableParams,
            pagination: {
              ...pagination,
              total: response.total,
            },
          });
        } else {
          window.antdNotify('錯誤', response.message, 'fail');
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const search = async () => {
    setTableParams({
      ...tableParams,
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
    updateData({
      current: 1,
      pageSize: 10,
    });
  };

  // 搜尋組件
  const SearchBar = () => {
    return (
      <>
        <Form form={searchForm} name="searchForm" onFinish={search}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="據點代碼" name="code">
                <Input allowClear={true} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="據點名稱" name="name">
                <Input allowClear={true} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
                className="button button--search">
                搜尋
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  // QRCode檢視
  const viewQRCode = (title, locationType, locationId, locationCode) => {
    setImageLoading(true);
    setModalImageData(null);
    setModalTitle(title);
    httpHandler
      .get(
        // id改用廠區代碼
        `/qr_code/image?location_type=${locationType}&location_id=${locationCode}`
      )
      .then((response) => {
        if (response.success) {
          setModalImageData(response.data.image);
        } else {
          window.antdNotify('錯誤', response.message, 'fail');
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setModalIsOpen(true);
        setImageLoading(false);
      });
  };

  // 表格組件
  const DataTable = () => {
    let columns = [
      {
        title: 'id',
        dataIndex: 'id',
        hidden: true,
      },
      {
        title: '操作',
        dataIndex: '',
        width: 120,
        render: (value, row) => {
          return (
            <Button
              icon={<QrcodeOutlined />}
              type="primary"
              className="button--edit"
              loading={imageLoading}
              onClick={() => {
                viewQRCode(
                  `${row.location_code}-${
                    locationTypeMapping[row.location_type]
                  }-${row.name}`,
                  row.location_type,
                  row.id,
                  row.location_code
                );
              }}>
              QR Code
            </Button>
          );
        },
      },
      {
        title: '代碼',
        dataIndex: 'location_code',
        width: 120,
      },
      {
        title: '類別',
        dataIndex: 'location_type',
        width: 120,
        render: (value, row) => {
          return locationTypeMapping[value];
        },
      },
      {
        title: '名稱',
        dataIndex: 'name',
      },
    ].filter((item) => !item.hidden);

    return (
      <Table
        columns={columns}
        rowKey={(row) => row.id}
        dataSource={tableData}
        pagination={tableParams.pagination}
        loading={loading}
        bordered="true"
        scroll={{ x: 'max-content' }}
        onChange={handleTableChange}
      />
    );
  };

  const QRCodeModal = ({ title, imageData, isOpen, setIsOpen }) => {
    const handleDownload = () => {
      const link = document.createElement('a');
      link.href = imageData;
      link.download = `${title}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return (
      <Modal
        title={title}
        open={isOpen}
        footer={[
          <Button onClick={handleDownload}>
            <DownloadOutlined />
            下載
          </Button>,
        ]}
        onCancel={() => {
          setIsOpen(false);
        }}>
        <Image src={imageData} preview={false} />
      </Modal>
    );
  };

  const section = [
    {
      hasTitle: false,
      title: 'Search',
      children: <SearchBar />,
    },
    {
      hasTitle: false,
      title: 'Data',
      children: <DataTable />,
    },
    {
      hasTitle: false,
      title: 'QRCodeModal',
      children: (
        <QRCodeModal
          title={modalTitle}
          imageData={modalImageData}
          isOpen={modalIsOpen}
          setIsOpen={setModalIsOpen}
        />
      ),
    },
  ];

  useEffect(() => {
    // 預設表單值
    searchForm.setFieldsValue({
      code: '',
      name: '',
    });
    // 進入畫面時執行一次搜尋
    search();
  }, []);

  return (
    <div>
      <PageHeader functionName={'qr_code'}></PageHeader>
      <PageBody section={section}></PageBody>
    </div>
  );
};

export default QRCode;
