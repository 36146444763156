import React, { useState, useEffect } from 'react';
import { Space, Row, Col, Divider, Spin } from 'antd';
import { WebviewHttpHandler } from 'shared/webviewHttpHandler';

const httpHandler = new WebviewHttpHandler();
const MaintenanceProgress = () => {
  const [maintainanceData, setMaintainanceData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    // 先打車主資料API取回車輛資料(如果有多筆則取第一筆)
    httpHandler
      .get(
        `/webview/maintenance_progress/get_progress?line_id=${localStorage.getItem(
          'lineUserId'
        )}`
      )
      .then((res) => {
        if (res.success) {
          if (Object.keys(res.data).length === 0) {
            window.antdNotify('', '查無保修進度資料', 'success');
            return;
          }
          setMaintainanceData(res.data);
          setLoading(false);
        } else {
          window.antdNotify('', res.data.errorMessages, '');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <Space direction="vertical" size="middle">
      <Space direction="vertical" size="small">
        <img
          src="/images/logo/toyota_logo_horizontal.svg"
          alt="Toyota Logo"
          style={{ width: '120px' }}
        />
        <div className="heading1">保修進度</div>
        <img
          src="/images/illustration/red_car.svg"
          alt="Car Illustration"
          style={{ width: '128px' }}
        />
        <div className="heading4 grey">{maintainanceData['CarNumber']}</div>
      </Space>
      <div className="info-container">
        <Spin spinning={loading}>
          <Row>
            <Col span={24}>
              <Row justify="space-between" align="start">
                <Col>
                  <div className="heading4 blue">
                    {maintainanceData['WORKNO']}
                  </div>
                </Col>
              </Row>
              <Divider style={{ margin: '16px 0' }} />
            </Col>
            <Col span={24}>
              <Space direction="vertical" size="small">
                <div className="heading1">{maintainanceData['STEPNM']}</div>
                <div className="body1">{maintainanceData['BRKDS']}</div>
              </Space>
              <Divider style={{ margin: '16px 0' }} />
            </Col>
            <Col span={24}>
              <Row justify="space-between" align="start">
                <Col>
                  <div className="body1">入廠日期</div>
                </Col>
                <Col>
                  <div className="body1 blue">{maintainanceData['RTPTDT']}</div>
                </Col>
              </Row>
              <Divider style={{ margin: '16px 0' }} />
            </Col>
            <Col span={24}>
              <Row justify="space-between" align="start">
                <Col>
                  <div className="body1">入廠里程</div>
                </Col>
                <Col>
                  <div className="body1 blue">{maintainanceData['RTPTML']}</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </div>
    </Space>
  );
};

export default MaintenanceProgress;
