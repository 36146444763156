import React, { useEffect, useState } from 'react';
import { Space, Col, Row, Empty, Spin } from 'antd';
import { WebviewHttpHandler } from 'shared/webviewHttpHandler';

const httpHandler = new WebviewHttpHandler();
// 排序寫死
const coldDrinksSort = [
  '咖啡',
  '美粒果柳橙汁',
  '可口可樂',
  '雪碧',
  '蜂蜜檸檬氣泡飲',
  '日式原萃綠茶',
];
const hotDrinksSort = ['咖啡', '紅茶', '花茶', '綠茶'];
const snackDrinksSort = ['喜憨兒手工餅乾', '精選米果', '精選餅乾'];
const MenuItem = ({ src, alt, title }) => (
  <Col xs={12} sm={6} md={6}>
    <div className="item-container">
      {src ? (
        <img src={src} alt={alt} style={{ width: '100%' }} />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={'尚未提供圖片'}
        />
      )}
      <div className="body1 text-ellipsis">{title}</div>
    </div>
  </Col>
);

const DrinkMenu = () => {
  const [userLocationCode, setUserLocationCode] = useState();
  const [searchStatus, setSearchStatus] = useState(1);
  const [coldDrinks, setColdDrinks] = useState([]);
  const [hotDrinks, setHotDrinks] = useState([]);
  const [snacks, setSnacks] = useState([]);
  // 重組response的item排序
  const reformatItemSort = (sortList, resList) => {
    var newList = [];
    sortList.map((item) => {
      const index = resList.findIndex((coldDrink) => coldDrink.name === item);

      if (index !== -1) {
        const sortItem = resList.splice(index, 1)[0];
        newList.push(sortItem);
      }
    });
    newList.push(...resList);
    return newList;
  };
  useEffect(() => {
    httpHandler
      .get(
        `user_location/get_user_location?user_id=${localStorage.getItem(
          'lineUserId'
        )}`
      )
      .then((res) => {
        if (res.success) {
          setUserLocationCode(res.data.site_id);
        } else {
          window.antdNotify('錯誤', res.data.message, 'fail');
        }
      });
  }, []);
  useEffect(() => {
    if (userLocationCode) {
      httpHandler
        .get(`/webview/custom_lounge/list?location_code=${userLocationCode}`)
        .then((res) => {
          if (res.success) {
            // 飲品菜單
            const snackList = res.data.filter((item) => item.item_type == '1');
            // 冷飲
            const coldDrinks = snackList.filter((item) => item.sub_type == '1');
            // 熱飲
            const hotDrinks = snackList.filter((item) => item.sub_type == '2');
            // 點心
            const snacks = snackList.filter((item) => item.sub_type == '3');
            // set state前先根據寫死的排序重組list
            setColdDrinks(reformatItemSort(coldDrinksSort, coldDrinks));
            setHotDrinks(reformatItemSort(hotDrinksSort, hotDrinks));
            setSnacks(reformatItemSort(snackDrinksSort, snacks));
            setSearchStatus(0);
          } else {
            window.antdNotify('錯誤', res.data.message, 'fail');
          }
        });
    }
  }, [userLocationCode]);
  return (
    // Webview客製化Title
    (document.title = '飲品Menu'),
    (
      <div className="menu-container">
        <Space direction="vertical" size="middle">
          <Space direction="vertical" size="small">
            <img
              src="/images/logo/toyota_logo_horizontal.svg"
              alt="Toyota Logo"
              style={{ width: '120px' }}
            />
            <div className="heading1">飲品Menu</div>
            <div className="body1 grey">
              實際品項以服務廠提供為主
              <br />
              歡迎向客服專員取用
            </div>
          </Space>
          {searchStatus === 1 ? (
            <Spin size="large" />
          ) : (
            <Space direction="vertical" size="middle">
              {hotDrinks.length > 0 && (
                <>
                  <div className="title1 text-left">熱飲</div>
                  <Row gutter={[16, 16]}>
                    {hotDrinks.map((item) => (
                      <MenuItem
                        key={item.id}
                        src={item.image_base64}
                        alt={item.name}
                        title={item.name}
                      />
                    ))}
                  </Row>
                </>
              )}
              {coldDrinks.length > 0 && (
                <>
                  <div className="title1 text-left">冷飲</div>
                  <Row gutter={[16, 16]}>
                    {coldDrinks.map((item) => (
                      <MenuItem
                        key={item.id}
                        src={item.image_base64}
                        alt={item.name}
                        title={item.name}
                      />
                    ))}
                  </Row>
                </>
              )}
              {snacks.length > 0 && (
                <>
                  <div className="title1 text-left">點心</div>
                  <Row gutter={[16, 16]}>
                    {snacks.map((item) => (
                      <MenuItem
                        key={item.id}
                        src={item.image_base64}
                        alt={item.name}
                        title={item.name}
                      />
                    ))}
                  </Row>
                </>
              )}
            </Space>
          )}
        </Space>
      </div>
    )
  );
};

export default DrinkMenu;
